<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="10" lg="10">
      <div id="seed-list-tab">
        <card-widget bodyStyle="px-4 pb-0">
          <template #cardBody>
            <v-row class="mb-8 d-flex" no-gutters>
              <v-col cols="12" md="10" lg="10">
                <h6 class="text-h6 font-weight-large pb-2">
                  {{ $dictionary.app.accountSettings.seedListTab.title }}
                </h6>
                <p class="subtitle-1 text--secondary primary--font mb-0">
                  {{ $dictionary.app.accountSettings.seedListTab.subTitle }}
                </p>
              </v-col>
              <v-col cols="12" md="2" lg="2" class="d-flex justify-end">
                <!-- Creates csv file and gets downloaded -->
                <csv-button
                  :labels="labels"
                  file-name="seeds"
                  :data="seeds"
                  :fields="csvFields"
                  :loading="isLoading"
                  :color="$appConfig.csvButton.color.primary"
                />
              </v-col>
            </v-row>

            <seeds-table
              :headers="headers"
              :isLoading="isLoading"
              :seeds="seeds"
              :table-settings="tableSettings"
              :update-settings="() => {}"
            />
          </template>
        </card-widget>

        <!-- Snackbar starts -->
        <snackbar
          :snackBarType="$appConfig.snackbar.snackbarTypes.error"
          :snackbarValue="hasError"
          @close="hasError = !hasError"
        >
          {{ errorMessage }}
        </snackbar>
        <!-- Snackbar ends -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CardWidget, CsvButton } from "../widgets";
import { seedListsHeaders } from "@/data/table-headers";
import { SeedsTable } from "@/components/tables";
import { SORTING_ORDERS, TABLE_PER_PAGES_OPTIONS } from "@/constants";
import { isEmpty } from "@/utils/common.utils";

/**
 * Seeds address constant
 */
const SEED_ADDRESS = "seed_address";

/**
 * Account settings seed lists tab
 */
export default {
  name: "SeedListTab",
  /**
   * ---------- Components ------------
   */
  components: {
    CardWidget,
    CsvButton,
    SeedsTable,
  },
  /**
   * ---------- Data properties ------------
   */
  data() {
    return {
      isLoading: false,
      hasError: false,
      errorMessage: "",
      labels: { [SEED_ADDRESS]: "Active Seeds" },
      csvFields: [SEED_ADDRESS],
      seeds: [],
      lastUpdatedDate: new Date(),
    };
  },
  /**
   * ---------- Computed properties ------------
   */
  computed: {
    ...mapGetters({
      seedList: "account/seeds",
      selectedAccount: "ui/selectedAccount",
    }),
    /**
     * Seed request payload
     */
    seedRequest() {
      const { account_id } = this.selectedAccount;
      return {
        account_id,
      };
    },
    /**
     * headers
     * @description Table headers and row columns
     * @type {Array}
     */
    headers() {
      /** seedAddressHeaders object name property value should always be {seed_address}  */
      let [seedAddressHeader] = seedListsHeaders;
      seedAddressHeader = {
        ...seedAddressHeader,
        text: "Active Seeds",
        sortable: false,
      };

      return [
        seedAddressHeader,
        {
          text: this.lastUpdatedSeedDate,
          sortable: false,
          class: "subtitle-2 text--secondary d-flex align-center justify-end",
        },
      ];
    },
    /**
     * tableSettings
     * Seeds table settings
     */
    tableSettings() {
      const [, TEN] = TABLE_PER_PAGES_OPTIONS;

      return {
        sort_by: "seed_address",
        sort_order: SORTING_ORDERS.asc,
        itemsPerPage: TEN,
        itemsPerPageOptions: TABLE_PER_PAGES_OPTIONS,
      };
    },
    /**
     * lastUpdatedSeedDate
     * Last updated seeds list date
     */
    lastUpdatedSeedDate() {
      const SEPERATOR = "at";

      const [lastSeedsDate] = this.$options.filters
        .formatDateToAmPm(this.lastUpdatedDate)
        .split(SEPERATOR);

      return `Seed List last updated ${lastSeedsDate}`;
    },
  },
  /**
   * ---------- Watching properties ------------
   */
  watch: {
    "selectedAccount.account_id"() {
      this.getSeedsList();
    },
  },
  /**
   * ---------- Methods ------------
   */
  methods: {
    ...mapActions({
      getSeeds: "account/getSeeds",
    }),
    /**
     * Fetches seed list from the store
     * @description dispatches seeds action in the store to consume seeds api
     */
    async getSeedsList() {
      try {
        this.isLoading = true;
        await this.getSeeds(this.seedRequest);
        this.seeds = !isEmpty(this.seedList?.seeds)
          ? this.seedList?.seeds?.map((seed) => ({
              seed_address: seed,
            }))
          : [];
        this.lastUpdatedDate =
          this.seedList?.updated_at ?? this.lastUpdatedDate;
      } catch (error) {
        this.errorMessage = error.message;
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
  /**
   * ---------- Created lifecycle hook ------------
   */
  async created() {
    await this.getSeedsList();
  },
};
</script>
