<template>
  <section id="account-settings">
    <v-row no-gutters class="pl-lg-2">
      <v-col cols="12" lg="12">
        <h1
          class="text-h5 font-weight-large secondary--font pt-6 text--primary"
        >
          Account Settings
        </h1>
        <br />
      </v-col>

      <v-tabs
        v-model="settingsTab"
        background-color="transparent"
        class="border-bottom-grey"
      >
        <v-tab
          class="px-12 subtitle-2 text-dark-grey font-weight-large"
          active-class="setting-active-tab"
          v-for="({ name, query, elementId }, i) in tabsName"
          :id="elementId"
          v-text="name"
          :key="i"
          @click="onTabChange(query, i)"
        >
        </v-tab>
      </v-tabs>

      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-tabs-items
            v-model="settingsTab"
            class="border-bottom-2 transparent"
          >
            <v-tab-item v-for="itemComponent in tabItems" :key="itemComponent">
              <keep-alive>
                <component :is="itemComponent" />
              </keep-alive>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-row>
  </section>
</template>

<script>
import { AccountSettingsForm } from "@/components/forms";
import { Users } from "@/components/forms";
import { SeedListTab, InboxMonitoringTab } from "@/components/tabs";

/**
 * Account settings view
 */
export default {
  name: "AccountSettings",
  /**
   * ---------- Components ------------
   */
  components: {
    AccountSettingsForm,
    Users,
    SeedListTab,
    InboxMonitoringTab,
  },
  /**
   * ---------------- Data attributes ------------------
   */
  data() {
    return {
      settingsTab: 0,
      tabsName: [
        {
          name: "Account",
          query: "account_information",
          elementId: "account_information",
        },
        {
          name: "Inbox monitoring",
          query: "inbox_monitoring",
          elementId: "inbox_monitoring",
        },
        { name: "Users", query: "users", elementId: "users" },
        { name: "Seed List", query: "seed_list", elementId: "seed_list" },
      ],
      tabItems: [
        "AccountSettingsForm",
        "InboxMonitoringTab",
        "Users",
        "SeedListTab",
      ],
    };
  },
  /**
   * ------------------ Methods ------------------
   */
  methods: {
    /**
     * Changes the route with current selected tab name in the route query
     * @param active_tab String Selected tab by the user
     */
    onTabChange(active_tab) {
      const isCurrentTabActive = this.$route.query?.active_tab === active_tab;

      if (!isCurrentTabActive) {
        this.$router.push({ path: this.$route.path, query: { active_tab } });
      }
    },
    /**
     * Computes the tab selected by the user on the basis of the route
     * active_tab query param
     */
    computeSelectedTab() {
      const selectedTab = this.$route.query?.active_tab || 0;
      const currentTab = selectedTab
        ? this.tabsName.findIndex(
            ({ query }) => query.toLowerCase() === selectedTab
          )
        : 0;
      // Sets settingsTab with computed tab value
      this.settingsTab = currentTab;
    },
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    /**
     * Computes current tab on the basis of route change
     */
    "$route.query"(val, oldVal) {
      const areDiff = val?.active_tab !== oldVal?.active_tab;
      areDiff && this.computeSelectedTab();
    },
  },
  /**
   * ---------- Before component mount ------------
   */
  beforeMount() {
    this.computeSelectedTab();
  },
};
</script>

<style lang="scss" scoped>
.setting-active-tab {
  color: var(--v-primary-base) !important;
}
</style>
