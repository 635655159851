<template>
  <v-row class="d-flex justify-center" id="inbox-monitoring">
    <v-col cols="12" md="10" lg="10">
      <loader :loading="isLoading" :size="$appConfig.loader.small" />
      <v-form
        ref="inboxForm"
        v-model="isFormValid"
        @submit.prevent="onSubmitHandler"
      >
        <div id="inbox-monitoring-tab">
          <threshold-setting-form :form-data="formData" />
          <card-widget bodyStyle="px-4 pb-0" v-if="isAdmin">
            <template #cardBody>
              <v-row class="mb-8 d-flex" no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                  class="pb-2 d-flex align-center"
                >
                  <h6 class="text-h6">
                    <span class="font-weight-large">
                      {{
                        $dictionary.app.accountSettings.inboxMonitoring.title
                      }}
                    </span>
                  </h6>
                  <span class="pl-1 body-2 text--secondary">
                    {{
                      $dictionary.app.accountSettings.inboxMonitoring.caption
                    }}
                  </span>
                </v-col>

                <p class="subtitle-1 text--secondary primary--font mb-0">
                  {{ $dictionary.app.accountSettings.inboxMonitoring.subTitle }}
                </p>
              </v-row>

              <mailbox-provider-tree-filter
                :get-providers="getProviders"
                @show-loader="isLoading = $event"
                @selected-providers="selectedMailboxProviders = $event"
              />
            </template>
          </card-widget>
          <v-divider class="mt-8 mb-6 border-light-grey" />
          <v-row no-gutters class="d-flex justify-start">
            <v-col cols="12">
              <v-btn
                medium
                v-text="'Save'"
                type="submit"
                color="primary"
                :disabled="!isAdmin"
                id="save-inbox-monitoring"
                class="px-4 button font-weight-bold"
              />
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CardWidget from "../widgets/CardWidget.vue";
import MailboxProviderTreeFilter from "../widgets/MailboxProviderTreeFilter.vue";
import { ThresholdSettingForm } from "../forms";
import {
  addAccountMailboxProviders,
  createAccount,
  getAccount,
} from "@/services";
import { isResponseCode, pick } from "@/utils/common.utils";
import { RESPONSE_CODES } from "@/constants";

/**
 * Inbox monitoring tab
 */
export default {
  name: "InboxMonitoringTab",
  /**
   * -------------- Components -------------
   */
  components: {
    CardWidget,
    ThresholdSettingForm,
    MailboxProviderTreeFilter,
  },
  /**
   * -------------- Data properties -------------
   */
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      selectedMailboxProviders: [],
      getProviders: false,
      formData: { inbox_threshold: 0, spam_threshold: 0, missing_threshold: 0 },
    };
  },
  /**
   * -------------- Computed properties -------------
   */
  computed: {
    ...mapGetters({
      selectedAccountId: "ui/selectedAccountId",
      selectedAccount: "ui/selectedAccount",
      isAdmin: "auth/isAdmin",
    }),
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    /**
     * Maps vuex store actions in the component
     */
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      getMailboxProviders: "account/getMailboxProviders",
    }),
    /**
     * Maps mutations in the component
     */
    ...mapMutations({
      updateTheshold: "ui/SET_SELECTED_ACCOUNT_THRESHOLD",
      setMailboxProvidersChanged: "settings/SET_MAILBOX_PROVIDERS_CHANGED",
      updateDashboardFilters: "settings/RESET_ACTIVE_TREND_WIDGET_FILTER",
    }),
    /**
     * Fetches selected account details
     */
    async fetchAccountDetails() {
      try {
        this.isLoading = true;
        const { data } = await getAccount({
          account_id: this.selectedAccountId,
        });
        this.formData = data ?? {};
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Fetches id of selected mailbox providers of the selected account
     */
    fetchProviders() {
      this.getProviders = !this.getProviders;
    },
    /**
     * Form submit handler
     * @description Updates the threshold settings of selected account
     * And mailbox providers for inbox monitoring of selected account
     */
    async onSubmitHandler() {
      if (!this.isFormValid) return this.$refs.inboxForm.validate();
      // Stops execution of the function if logged in user is not an Admin
      if (!this.isAdmin) return;
      try {
        this.isLoading = true;
        await this.fetchProviders();
        const { status, data } = await createAccount(this.formData);
        this.onAccountUpdateSuccess(data);

        await this.addMailboxProvidersToAccount();
        if (isResponseCode(status, RESPONSE_CODES.success)) {
          const { inboxMonitoring } = this.$dictionary.app.accountSettings;

          this.setSnackbar({
            value: true,
            type: this.$appConfig.snackbar.snackbarTypes.success,
            message: inboxMonitoring.successMsg,
          });
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.error,
          message: this.$errorMessage(error),
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Adds or updates selected account mailbox providers if loged in user
     * has admin access of selected accoun
     */
    async addMailboxProvidersToAccount() {
      try {
        const [id] = this.selectedMailboxProviders.mailbox_providers;
        const payload = [
          this.selectedAccountId,
          id,
          this.selectedMailboxProviders,
        ];

        await addAccountMailboxProviders(...payload);
        this.setMailboxProvidersChanged(true);
        this.updateDashboardFilters();
      } catch (error) {
        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.error,
          message: this.$errorMessage(error),
        });
      }
    },
    /**
     * Commits mutation to update threshold in the store if account gets updated successfully
     */
    onAccountUpdateSuccess(data) {
      const props = ["inbox_threshold", "spam_threshold", "missing_threshold"];
      data && this.updateTheshold(pick(data, props));
    },
  },
  /**
   * -------------- Watching properties -------------
   */
  watch: {
    selectedAccountId(val) {
      val && this.fetchAccountDetails();
    },
  },
  /**
  |--------------------------------------------------
  | Created lifecycle hook
  |--------------------------------------------------
  */
  created() {
    this.selectedAccountId && this.fetchAccountDetails();
  },
};
</script>
